.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.sin-datos {
    padding: 25%;
    text-align: center;
}

.dashboard-table {
    max-height: 50vh;
    overflow-y: auto;
    display: block;
    width: 100%;
    min-width: 97vw;

    tr {
        td, th {
            border: solid 1px #ccc;
        }
    }
}

canvas { 
    height: 100% !important; 
    width: 100% !important;
}

.chart-wrapper {
    //margin-top: 3vh;
}

.kpi-card {
    height: calc(60vh/2)!important;
    width: calc(100vw/4)!important;

    .card-body {
        padding: 1rem;
    }
}

.title-kpi {
    font-size: 1em;
}

th, td{
    text-align: center;
    vertical-align: middle !important;
    min-width: 170px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: solid 1px #333;
}

td.HIGH_RISK {
    background-color: rgb(255, 0, 0, 0.7) !important;
}

td.MEDIUM_RISK {
    background-color: rgb(255, 128, 0, 0.7) !important;
}

td.LOW_RISK {
    background-color: rgb(255, 255, 0, 0.7) !important;
}

td.NORMAL {
    background-color: rgb(0, 143, 57, 0.7) !important;
}

.breadcrumb {
    padding-bottom: 0;
    padding-left: 0;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.table-bordered {
    border-radius: 5px;
}

.tooltip-mttr {
    text-align: start;
    font-size: 1.2rem;
    font-weight: bold;
}