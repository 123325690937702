.container-fluid, .content {
    padding: 5px;
}

.filter-slider-pane {
    .slide-pane__content {
        padding: 5px;
    }
}

.min-width-100 {
    min-width: 120px !important;
}

.border-white {
    border: white 1px solid !important;
    border-radius: 5px;
}

.custom-overlay-class {
    right: unset !important;
    top: 105px !important;
    background-color: transparent !important;
}

.custom-filter-overlay-class {
    left: unset !important;
    top: 105px !important;
    background-color: transparent !important;
}

.kpi-sliding-pane {
    width: 100% !important;
    height: 150px !important;
    position: absolute;
    bottom: 0;

    .slide-pane__content {
        padding: 0;
    }

    .card .card-header {
        min-height: 50px !important;
    }
}

.kpi-sliding-pane-overlay {
    top: unset !important;
    background-color: transparent !important;
}

.zone-sliding-pane {
    margin-top: 0;

    .slide-pane__content {
        padding: 0;
        padding-bottom: 40px;
    }
    
}

.line-sliding-pane {
    margin-top: 0;

    .slide-pane__content {
        padding: 0;
        padding-bottom: 40px;
    }
    
}

.details-sliding-pane {

    overflow-y: auto;
    height: 100%;
    margin-top: 0;

    .slide-pane__content {
        padding: 0;
        padding-bottom: 40px;
    }
    
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
   
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}